import React from 'react'
import { Outlet, Link } from "react-router-dom";
import logo from '../../assest/img/seek2lrn-logo-final.png'
export default function Sidebar() {
  return (
    <>
     <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

{/* <!-- Sidebar - Brand --> */}
<Link class="sidebar-brand d-flex align-items-center justify-content-center" to="/">
    
    <div class="sidebar-brand-text mx-3">
     <img src={logo} alt="Seek2Learn" className='logo' />
    </div>
</Link>

{/* <!-- Divider --> */}
<hr class="sidebar-divider my-0"/>

{/* <!-- Nav Item - Dashboard --> */}
<li class="nav-item active">
    <Link class="nav-link" to="/">
        <i class="fas fa-fw fa-tachometer-alt"></i>
        <span>DASHBOARD</span></Link>
</li>

{/* <!-- Divider --> */}
<hr class="sidebar-divider"/>

{/* <!-- Nav Item - Pages Collapse Menu --> */}
<li class="nav-item">
    <Link class="nav-link collapsed" to="/tutormanagement" data-toggle="collapse" data-target="#collapseTwo"
        aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span>Tutor Management</span>
    </Link>
</li>

<li class="nav-item">
    <Link class="nav-link collapsed" to="/studentmanagement" data-toggle="collapse" data-target="#collapseTwo"
        aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span>Student Management</span>
    </Link>
</li>
<li class="nav-item">
    <Link class="nav-link collapsed" to="/coursemanagement" data-toggle="collapse" data-target="#collapseTwo"
        aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span>Course Management</span>
    </Link>
</li>
<li class="nav-item">
    <Link class="nav-link collapsed" to="/categoryManagement" data-toggle="collapse" data-target="#collapseTwo"
        aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span>Category Management</span>
    </Link>
</li>
<li class="nav-item">
    <Link class="nav-link collapsed" to="/subcategory" data-toggle="collapse" data-target="#collapseTwo"
        aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span>Sub Category Management</span>
    </Link>
</li>

<li class="nav-item">
    <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
        aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span>Management Organization</span>
    </a>
</li>

<li class="nav-item">
    <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
        aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span>Management Classes</span>
    </a>
</li>
<li class="nav-item">
    <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
        aria-expanded="true" aria-controls="collapseTwo">
        <i class="fas fa-fw fa-cog"></i>
        <span>Enrollment History</span>
    </a>
</li>

{/* <!-- Divider --> */}
<hr class="sidebar-divider d-none d-md-block"/>

{/* <!-- Sidebar Toggler (Sidebar) --> */}

</ul>
    </>
  )
}
