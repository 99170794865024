import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'

export default function AddStudent() {
  return (
    <>
    <Sidebar/>
    <div id="content-wrapper" class="d-flex flex-column">
       <div id="content">
      <Header/>

  
  <div class="container-fluid">
  <h1 class="h3 mb-4 text-gray-800">Add Student</h1>
    <div className="row">
    <div className="col-md-12">
        <div class="card shadow mb-4">
                                    
            <a href="#collapseCardExample" class="d-block card-header py-3" data-toggle="collapse"
                role="button" aria-expanded="true" aria-controls="collapseCardExample">
                <h5 class="m-0 font-weight-bold text-primary">Basic Info</h5>
            </a>
                                
            <div class="collapse show" id="collapseCardExample">
                <div class="card-body">
                <div className="row">
                    
                    <div class="mb-3 col-md-6">
                        <label class="mb-1" for="inputLastName"> First Name</label>
                        <input class="form-control" id="inputLastName" type="text" placeholder="" value=""/>
                    </div>

                    <div class="mb-3 col-md-6">
                                <label class="mb-1" for="inputFirstName">Last Name </label>
                                <input class="form-control" id="inputFirstName" type="text" placeholder="Last Name" value=""/>
                    </div>

                </div> {/* row */}
                <div className="row">
                    
                    <div class="mb-3 col-md-6">
                        <label class="mb-1" for="inputLastName"> Class</label>
                        <input class="form-control" id="" type="text" placeholder="" value=""/>
                    </div>

                    <div class="mb-3 col-md-6">
                                <label class="mb-1" for="inputFirstName">Enrolled Course </label>
                                <select class="form-select">
                                    <option>IIT JEE</option>
                                    <option>Fashion/Interior/Web</option>
                                    <option>Network Essentials</option>
                                    <option>C Programming</option>
                            </select>
                    </div>

                </div> {/* row */}
                <div className="row">
                    <div className="col-md-4">
                        <button className='btn btn-primary w-50'>Edit</button>
                    </div>
                </div>
                </div>
            </div>
        </div>

    </div>

    <div className="col-md-12">
        <div class="card shadow mb-4">
                                    
            <a href="#collapseCardExample" class="d-block card-header py-3" data-toggle="collapse"
                role="button" aria-expanded="true" aria-controls="collapseCardExample">
                <h5 class="m-0 font-weight-bold text-primary">Login Credentials</h5>
            </a>
                                
            <div class="collapse show" id="collapseCardExample">
                <div class="card-body">
                <div className="row">
                   <div class="mb-3 col-md-6">
                        <label class="mb-1" for="inputLastName"> Email</label>
                        <input class="form-control" id="" type="email" placeholder="" value=""/>
                    </div>
                    <div class="mb-3 col-md-6">
                        <label class="mb-1" > Password</label>
                        <input class="form-control" id="" type="password" placeholder="" value="suraj"/>
                    </div>
                    <div class="mb-3 col-md-12">
                        <button className='btn btn-primary'>Save</button>
                    </div>
                   </div>
               </div>
            </div>
        </div>

    </div>

    <div className="col-md-12">
        <div class="card shadow mb-4">
                                    
            <a href="#collapseCardExample" class="d-block card-header py-3" data-toggle="collapse"
                role="button" aria-expanded="true" aria-controls="collapseCardExample">
                <h5 class="m-0 font-weight-bold text-primary">Payment Info</h5>
            </a>
                                
            <div class="collapse show" id="collapseCardExample">
                <div class="card-body">
                <div className="row">
                   <div class="mb-3 col-md-6">
                        <label class="mb-1 fs-5" for="inputLastName"> Maureen Biologist</label>
                        <p>Maureenbiologist@gmail.com, <br/>  24 Veterans Drive, Wagner,sd, 53380  United States</p>
                      
                    </div>
                    <div class="mb-3 col-md-6">
                        <label class="mb-1"> Payment Status </label>
                      <p>
                      <button className='btn btn-success'>Payment Success</button>
                      </p>
                    </div>
                   
               </div>
                </div>
            </div>
        </div>

    </div>

    </div>







  </div>
  </div>
    <Footer/>
    
  </div> 
    
    
    </>
  )
}
