import React from 'react'
import '../../Pages/Pages.scss'
import TutorImg from '../../assest/img/undraw_profile_1.svg'
import { Button } from 'bootstrap'
import Footer from '../Footer/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { Outlet, Link } from "react-router-dom";

export default function StudentManagement() {
    const number = [...new Array(20)]
  return (
    <>
    <Sidebar/>
    <div id="content-wrapper" class="d-flex flex-column">
       <div id="content">
      <Header/>
    <div class="container-fluid">
    <h1 class="h3 mb-4 text-gray-800">Student Management</h1>

    <div class="card shadow mb-4">

                    <div class="card-header py-3 d-flex justify-content-between align-items-center">
                        <h6 class="m-0 font-weight-bold text-primary">Student Management</h6>
                        <Link to="add-student" className='btn btn-primary'>Add Student</Link>
                    </div>
                        
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered tutortable" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Class</th>
                                            <th>Enrolled Course</th>
                                            <th align='center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
 <tr>
    <td>
    <div className='img-profile rounded-circle'>
        <img src={TutorImg} alt="Tutor image" />
    </div>
    </td>
    <td>Patty O'Furniture.</td>
    <td>Pattyfurniture@gmail.com</td>
    <td>12th</td>
    <td>Linux Shell Scripting</td>
    <td align='center'>
    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-student" className='btn btn-warning btn-sm mr-2'>Edit</Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div>  
    </td>
</tr>

<tr>
    <td>
    <div className='img-profile rounded-circle'>
        <img src={TutorImg} alt="Tutor image" />
    </div>
    </td>
    <td>Paddy O'Furniture</td>
    <td>Paddyofurniture@gmail.com</td>
    <td>B.Arch</td>
    <td>Database Management System</td>
    <td align='center'>
    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-student" className='btn btn-warning btn-sm mr-2'>Edit</Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div>  
    </td>
</tr>


<tr>
    <td>
    <div className='img-profile rounded-circle'>
        <img src={TutorImg} alt="Tutor image" />
    </div>
    </td>
    <td>Maureen Biologist.</td>
    <td>Maureenbiologist@gmail.com</td>
    <td>12th</td>
    <td>Fashion/Interior/Web</td>
    <td align='center'>
    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-student" className='btn btn-warning btn-sm mr-2'>Edit</Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div>  
    </td>
</tr>


<tr>
    <td>
    <div className='img-profile rounded-circle'>
        <img src={TutorImg} alt="Tutor image" />
    </div>
    </td>
    <td>Olive Yew.</td>
    <td>Oliveyew..@gmail.com</td>
    <td>BPharma</td>
    <td>Computer Maintenance Essentials</td>
    <td align='center'>
    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-student" className='btn btn-warning btn-sm mr-2'>Edit</Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div>  
    </td>
</tr>

<tr>
    <td>
    <div className='img-profile rounded-circle'>
        <img src={TutorImg} alt="Tutor image" />
    </div>
    </td>
    <td>Olive Yew.</td>
    <td>Oliveyew..@gmail.com</td>
    <td>B.Sc</td>
    <td>Network Essentials</td>
    <td align='center'>
    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-student" className='btn btn-warning btn-sm mr-2'>Edit</Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div>  
    </td>
</tr>


<tr>
    <td>
    <div className='img-profile rounded-circle'>
        <img src={TutorImg} alt="Tutor image" />
    </div>
    </td>
    <td>Olive Yew.</td>
    <td>Oliveyew..@gmail.com</td>
    <td>11th</td>
    <td>C Programming</td>
    <td align='center'>
    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-student" className='btn btn-warning btn-sm mr-2'>Edit</Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div>  
    </td>
</tr>














        {
            number.map(data=>{

        return (
            <tr>
    <td>
        <div className='img-profile rounded-circle'>
            <img src={TutorImg} alt="Tutor image" />
        </div>
    </td>
    <td>Allie Grater</td>
    <td>Alliegrater@gmail.com</td>
    <td>12</td>
    <td>IIT JEE</td>
    <td align='center'>
    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-student" className='btn btn-warning btn-sm mr-2'>Edit</Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div>  
    </td>
</tr>
        )

            })
        }


                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
    </div>

     </div>
     </div>
    <Footer/>
    
  </div> 

    </>
  )
}
