import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'

export default function AddCourse() {
  return (
    <>
    <Sidebar/>
    <div id="content-wrapper" class="d-flex flex-column">
       <div id="content">
      <Header/>

  
  <div class="container-fluid">
  <h1 class="h3 mb-4 text-gray-800">Add Course</h1>
  <div className="row">
        <div className="col-md-12">

        <div class="card shadow mb-4">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">Add a course</h6>
            </div>
            <div class="card-body">
              <form action="">
                <div className="row">

                <div className="col-md-6">
                   <div class="mb-3"><label class="mb-1" for="inputFirstName">Course Name </label><input class="form-control" id="inputFirstName" type="text" placeholder="Enter your  name" value=""/></div>
                </div>

                <div className="col-md-6">
                   <div class="mb-3"><label class="mb-1" for="inputFirstName">Category </label><input class="form-control" id="inputFirstName" type="text" placeholder="Enter your  name" value=""/></div>
                </div>


                <div className="col-md-6">
                   <div class="mb-3"><label class="mb-1" for="inputFirstName">Sub Category </label><input class="form-control" id="inputFirstName" type="text" placeholder="Enter your  name" value=""/></div>
                </div>

                <div className="col-md-6">
                   <div class="mb-3"><label class="mb-1" for="inputFirstName">Created Account </label><input class="form-control" id="inputFirstName" type="text" placeholder="Enter your  name" value=""/></div>
                </div>

                <div className="col-md-12">
                   <div class="mb-3"><label class="mb-1" for="inputFirstName">Desciption </label>
                   <textarea class="form-control" id="inputFirstName" type="text" placeholder="Enter your  name">
                    </textarea>
                   </div>
                </div>

                <div className="col-md-6">
                   <div class="mb-3"><label class="mb-1" for="inputFirstName">Course Type </label><input class="form-control" id="inputFirstName" type="text" placeholder="Enter your  name" value=""/></div>
                </div>

                <div className="col-md-6">
                   <div class="mb-3"><label class="mb-1" for="inputFirstName">Course Price</label>
                        <select className='form-select'>
                            <option>1200</option>
                            <option>1200</option>
                            <option>1200</option>
                            <option>1200</option>
                            <option>1200</option>
                            <option>1200</option>
                        </select>
                   </div>
                </div>


                <div className="col-md-6">
                   <div class="mb-3"><label class="mb-1" for="inputFirstName">Course  DIscount price </label><input class="form-control" id="inputFirstName" type="text" placeholder="Enter your  name" value=""/></div>
                </div>

                <div className="col-md-6">
                   <div class="mb-3"><label class="mb-1" for="inputFirstName">Update Media</label><input class="form-control" id="inputFirstName" type="file" placeholder="Enter your  name" value=""/></div>
                </div>

                <div className="col-md-6">
  <button className='btn btn-primary w-50'>Save</button>

                </div>

                </div>

              </form>  
            </div>
        </div>


        </div>

  </div>

  </div>
  </div>
    <Footer/>
    
  </div> 
    
    
    </>
  )
}
