import React from 'react'
import { Outlet, Link } from "react-router-dom";
import Footer from '../Footer/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'


export default function AddTutor() {
  return (
    <>
    <Sidebar/>
    <div id="content-wrapper" class="d-flex flex-column">
       <div id="content">
      <Header/>

  
  <div class="container-fluid">
  <h1 class="h3 mb-4 text-gray-800">Add a Teacher</h1>

    <div className="row">

        <div className="col-md-12">

        <div class="card shadow mb-4">
                                {/* <!-- Card Header - Accordion --> */}
                                <a href="#collapseCardExample" class="d-block card-header py-3" data-toggle="collapse"
                                    role="button" aria-expanded="true" aria-controls="collapseCardExample">
                                    <h5 class="m-0 font-weight-bold text-primary">Add a Teacher</h5>
                                </a>
                                {/* <!-- Card Content - Collapse --> */}
                                <div class="collapse show" id="collapseCardExample">
                                    <div class="card-body">
                                        

                                    <div class="row">

                                      <div className="col-md-10">

                                    <div className="row">
                                      <div class="mb-3 col-md-6">
                                            <label class="mb-1" for="inputFirstName">Name </label>
                                            <input class="form-control" id="inputFirstName" type="text" placeholder="Enter your  name" value=""/>
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class=" mb-1" for="inputLastName">Mobile number </label>
                                            <input class="form-control" id="inputLastName" type="tel" placeholder="Enter your mobile number" value=""/>
                                        </div>

                                    </div> {/* row */}
                                    <div className="row">
                                      <div class="mb-3 col-md-12">
                                            <label class=" mb-1" for="inputFirstName">Address Detail </label>
                                            <input class="form-control" id="inputFirstName" type="text" placeholder="Enter your  address detail" value=""/>
                                        </div>
                                        

                                    </div> {/* row */}
                                    <div className="row">
                                      <div class="mb-3 col-md-6">
                                            <label class=" mb-1" for="inputFirstName">Preferred language </label>
                                            <input class="form-control" id="inputFirstName" type="text" placeholder="Preferred language" value=""/>
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class=" mb-1" for="inputLastName">Email id</label>
                                            <input class="form-control" id="inputLastName" type="text" placeholder="Enter your last name" value=""/>
                                        </div>

                                    </div> {/* row */}

                                    <div className="row">
                                      <div class="mb-3 col-md-12">
                                            <label class=" mb-1" for="inputFirstName"> About the tutor  </label>
                                            <textarea class="form-control" id="inputFirstName" type="text" placeholder="About the tutor"></textarea>
                                            
                                        </div>
                                        

                                    </div> {/* row */}

                                    <div className="row">
                                      <div class="mb-3 col-md-6">
                                            <label class="mb-1" for="inputFirstName">Qualification </label>
                                            <input class="form-control" id="inputFirstName" type="text" placeholder="Qualification" value=""/>
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class=" mb-1" for="inputLastName">Add courses</label>
                                            <select class="form-select">
                                                <option>Database Management System</option>
                                                <option>Fashion/Interior/Web</option>
                                                <option>Network Essentials</option>
                                                <option>C Programming</option>

                                            </select>
                                            
                                        </div>

                                    </div> {/* row */}
                                    <div className="row">
                                      
                                        <div class="mb-3 col-md-12">
                                            <label class=" mb-1" for="inputLastName">Submit certification done</label>
                                            <input class="form-control" id="inputLastName" type="file" placeholder="Submit certification done" value=""/>
                                        </div>

                                    </div> {/* row */}
                                    <div className="row">
                                      <div class="mb-3 col-md-6">
                                            <label class=" mb-1" for="inputFirstName">Age </label>
                                            <input class="form-control" id="inputFirstName" type="text" placeholder="Enter your Age" value=""/>
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class=" mb-1" for="inputFirstName">Specialization </label>
                                            <input class="form-control" id="inputFirstName" type="text" placeholder="Specialization" value=""/>
                                        </div>

                                    </div> {/* row */}
                                    <div className="row">
                                      
                                        <div class="mb-3 col-md-6">
                                            <label class=" mb-1" for="inputLastName">Submit your previous work detail</label>
                                            <input class="form-control" id="inputLastName" type="file" placeholder="" value=""/>
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class=" mb-1" for="inputFirstName">Experience in years </label>
                                            <input class="form-control" id="inputFirstName" type="text" placeholder="Experience in years" value=""/>
                                        </div>

                                    </div> {/* row */}

                                    <div className="row">
                                      
                                        <div class="mb-3 col-md-6">
                                            <label class=" mb-1" for="inputLastName">Tutoring location</label>
                                            <input class="form-control" id="inputLastName" type="text" placeholder="" value=""/>
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class=" mb-1" for="inputFirstName">Hourly price </label>
                                            <input class="form-control" id="inputFirstName" type="text" placeholder="Hourly price" value=""/>
                                      </div>

                                    </div> {/* row */}
                                    <div className="row">
                                      
                                        <div class="mb-3 col-md-6">
                                            <label class="mb-1" for="inputLastName"> Submit educational degree done</label>
                                            <input class="form-control" id="inputLastName" type="file" placeholder="" value=""/>
                                        </div>

                                        <div class="mb-3 col-md-6">
                                                    <label class="mb-1" for="inputFirstName"> Submit KYC Details </label>
                                                    <input class="form-control" id="inputFirstName" type="file" placeholder="Specialization" value=""/>
                                             </div>

                                    </div> {/* row */}
                                 
                                     <div class="row">
                                    
                                            <div class="mb-3 col-md-12">
                                            <hr class="my-4"/>
                                                <h5 class="card-title mb-4">Enter your Bank details</h5>
                                                <form>
                                                    <div class="row gx-3">
                                                        <div class="mb-3 col-md-6">
                                                            <label class="mb-1" for="inputBillingName">Account Name</label>
                                                            <input class="form-control" id="inputBillingName" type="text" placeholder="Enter the name as it appears on your card" value="Valerie Luna"/>
                                                        </div>
                                                        <div class="mb-3 col-md-6">
                                                            <label class="mb-1" for="inputBillingCCNumber">Account Number</label>
                                                            <input class="form-control" id="inputBillingCCNumber" type="text" placeholder="Enter your credit card number" value="4444 3333 2222 1111"/>
                                                        </div>
                                                    </div>
                                                    <div class="row gx-3">
                                                        <div class="col-md-4 mb-4 mb-md-0">
                                                            <label class="mb-1" for="inputOrgName">IFSC Code</label>
                                                            <input class="form-control" id="inputOrgName" type="text" placeholder="IFSC Code" value=""/>
                                                        </div>
                                                        <div class="col-md-4 mb-4 mb-md-0">
                                                            <label class="mb-1" for="inputLocation">Bank Name</label>
                                                            <input class="form-control" id="inputLocation" type="text" placeholder="Bank Name" value=""/>
                                                        </div>
                                                        <div class="col-md-4 mb-0">
                                                            <label class="mb-1" for="inputLocation">Branch Name</label>
                                                            <input class="form-control" id="inputLocation" type="text" placeholder="Branch Name" value=""/>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        
                                      <div className="row">
                                        <div className="col-md-4"><button type='submit' className='btn btn-primary'>Save</button></div>
                                      </div>
                                    </div>
                                    </div> {/* row */}
                                    </div>
                                </div>
                            </div>



        </div>

    </div>

  </div>
  </div>
    <Footer/>
    
  </div> 
    
    
    </>
  )
}
