import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { Outlet, Link } from "react-router-dom";
import TutorImg from '../../assest/img/undraw_profile_1.svg'

export default function SubCategory() {
    const number = [...new Array(20)]
  return (
    <>
    <Sidebar/>
    <div id="content-wrapper" class="d-flex flex-column">
       <div id="content">
      <Header/>

  
  <div class="container-fluid">
  <h1 class="h3 mb-4 text-gray-800">Sub Category Management</h1>
  <div className="row">
    <div className="col-md-12">
        <div class="card shadow mb-4">
                                    
            <a href="#collapseCardExample" class="card-header py-3 d-flex justify-content-between align-items-center" data-toggle="collapse"
                role="button" aria-expanded="true" aria-controls="collapseCardExample">
                <h5 class="m-0 font-weight-bold text-primary">Sub Category Management</h5>
                <Link to="add-sub-category" className='btn btn-primary'>Add Sub Category </Link>
            </a>
                                
            <div class="collapse show" id="collapseCardExample">
                <div class="card-body">
                <div class="table-responsive">
                                <table class="table table-bordered tutortable" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th> Parent Category Name</th>
                                            <th>Sub category Name</th>
                                            <th>Description</th>
                                           
                                            <th style={{'textAlign':'center'}}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
 <tr>
    <td>
    <div className='img-profile rounded-circle'>
        <img src={TutorImg} alt="Tutor image" />
    </div>
    </td>
    <td>Patty O'Furniture.</td>
    <td>Pattyfurniture</td>
    <td>In publishing and graphic design</td>
    <td align='center'>
    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-sub-category"><button className='btn btn-warning btn-sm mr-2'>Edit</button></Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div> 
    </td>
</tr>

<tr>
    <td>
    <div className='img-profile rounded-circle'>
        <img src={TutorImg} alt="Tutor image" />
    </div>
    </td>
    <td>Paddy O'Furniture</td>
    <td>Paddyofurniture</td>
    <td>In publishing and graphic design</td>
    <td align='center'>
    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-sub-category"><button className='btn btn-warning btn-sm mr-2'>Edit</button></Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div> 
    </td>
</tr>


<tr>
    <td>
    <div className='img-profile rounded-circle'>
        <img src={TutorImg} alt="Tutor image" />
    </div>
    </td>
    <td>Maureen Biologist.</td>
    <td>Maureenbiologist</td>
    <td>In publishing and graphic design Lorem ipsum</td>
    <td align='center'>
    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-sub-category"><button className='btn btn-warning btn-sm mr-2'>Edit</button></Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div> 

    </td>
</tr>


<tr>
    <td>
    <div className='img-profile rounded-circle'>
        <img src={TutorImg} alt="Tutor image" />
    </div>
    </td>
    <td>Olive Yew.</td>
    <td>OliveyewYew</td>
    <td>In publishing and graphic design Lorem</td>
    <td align='center'>

    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-sub-category"><button className='btn btn-warning btn-sm mr-2'>Edit</button></Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div> 

    </td>
</tr>

<tr>
    <td>
    <div className='img-profile rounded-circle'>
        <img src={TutorImg} alt="Tutor image" />
    </div>
    </td>
    <td>Olive Yew.</td>
    <td>Oliveyew</td>
    <td>In publishing and graphic design Lorem ipsum</td>
    <td align='center'>
    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-sub-category"><button className='btn btn-warning btn-sm mr-2'>Edit</button></Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div> 

    </td>
</tr>


<tr>
    <td>
    <div className='img-profile rounded-circle'>
        <img src={TutorImg} alt="Tutor image" />
    </div>
    </td>
    <td>Olive Yew.</td>
    <td>Oliveyewcom</td>
    <td>In publishing and graphic design Lorem ipsum</td>
    <td align='center'>

    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-sub-category"><button className='btn btn-warning btn-sm mr-2'>Edit</button></Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div> 

    </td>
</tr>














        {
            number.map(data=>{

        return (
            <tr>
    <td>
        <div className='img-profile rounded-circle'>
            <img src={TutorImg} alt="Tutor image" />
        </div>
    </td>
    <td>Allie Grater</td>
    <td>Alliegrater@gmail.com</td>
    <td>In publishing and graphic design Lorem ipsum</td>
    <td align='center'>
    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <button className='btn btn-warning btn-sm mr-2'>Edit</button>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div>  
    </td>
</tr>
        )

            })
        }


                                        
                                    </tbody>
                                </table>
                            </div>
                <div className="row">
                    <div className="col-md-4">
                        <button className='btn btn-primary w-50'>Edit</button>
                    </div>
                </div>
                </div>
            </div>
        </div>

    </div>
    </div>


  </div>
  </div>
    <Footer/>
    
  </div> 
    
    
    </>
  )
}
