import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'

export default function AddSubCategory() {
  return (
    <>
    <Sidebar/>
    <div id="content-wrapper" class="d-flex flex-column">
       <div id="content">
      <Header/>

  
  <div class="container-fluid">
  <h1 class="h3 mb-4 text-gray-800">Add Sub Category</h1>
   <div className='row'>
        <div className='col-md-12'>

        <div class="card shadow mb-4">
                                <div class="card-header py-3">
                                    <h6 class="m-0 font-weight-bold text-primary">Add Sub Category</h6>
                                </div>
                                <div class="card-body">
                                <div class="collapse show" id="collapseCardExample">
                <div class="card-body">
                <div className="row">
                    
                    <div class="mb-3 col-md-6">
                        <label class="mb-1" for="inputLastName"> Category Name</label>
                        <select className='form-select'>
                            <option>Category One</option>
                            <option>Category Two</option>
                            <option>Category Three</option>
                            
                        </select>
                    </div>

                    <div class="mb-3 col-md-6">
                                <label class="mb-1" for="inputFirstName">Sub Category Name </label>
                                <input class="form-control" id="inputFirstName" type="text" placeholder="Category Name " value=""/>
                    </div>

                </div> {/* row */}
                <div className="row">
                    <div class="mb-3 col-md-12">
                        <label class="mb-1" for="inputLastName"> Description  </label>
                        <textarea class="form-control" id="inputFirstName" type="text" placeholder="Description">
                        </textarea>
                    </div>
                </div> {/* row */}

                <div className="row">
                    <div class="mb-3 col-md-12">
                        <label class="mb-1" for="inputLastName"> Images </label>
                        <input class="form-control" id="" type="file" placeholder="" value="" />
                    </div>
                </div> {/* row */}


                <div className="row">
                    <div className="col-md-4">
                        <button className='btn btn-primary w-50'>Save</button>
                    </div>
                </div>
                </div>
            </div>
                                </div>
                            </div>




        </div>
   </div>

  </div>
  </div>
    <Footer/>
    
  </div> 
    
    
    </>
  )
}
