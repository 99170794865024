import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import Login from './Pages/Login/Login';
import Layout from './Pages/Layout/Layout';
import Dashboard from './Pages/Dashboard/dashboard';
import StudentManagement from './Pages/StudentManagement/StudentManagement';
import TutorManagement from './Pages/TutorManagement/TutorManagement';
import AddTutor from './Pages/TutorManagement/AddTutor';
import AddStudent from './Pages/StudentManagement/AddStudent';
import CourseManagement from './Pages/CourseManagement/CourseManagement';
import AddCourse from './Pages/CourseManagement/AddCourse';
import CategoryManagement from './Pages/CategoryManagement/CategoryManagement';
import AddCategory from './Pages/CategoryManagement/AddCategory';
import EditCategory from './Pages/CategoryManagement/EditCategory';
import SubCategory from './Pages/SubCategoryManagement/SubCategory';
import AddSubCategory from './Pages/SubCategoryManagement/AddSubCategory';

function App() {
  return (
    <>
    <body id="page-top">
     <div id="wrapper">
     <Router>
        <Routes>
           <Route path="/" element={ <Dashboard/>} />

           <Route path="tutormanagement">
              <Route path='' element={<TutorManagement/>} />
              <Route path='add-teacher' element={<AddTutor/>} />
           </Route>
          

           <Route path="studentmanagement">
              <Route path='' element={<StudentManagement/>} />
              <Route path='add-student' element={<AddStudent/>} />
           </Route>
           
           <Route path="coursemanagement">
              <Route path='' element={<CourseManagement/>} />
              <Route path='add-course' element={<AddCourse/>} />
           </Route>

           <Route path="categoryManagement">
              <Route path='' element={<CategoryManagement/>} />
              <Route path='add-category' element={<AddCategory/>} />
              <Route path='edit-category' element={<EditCategory/>} />
           </Route>

           
              <Route path='/subcategory' element={<SubCategory/>} />
              
              <Route path="subcategory">
              <Route path='' element={<SubCategory/>} />
              <Route path='add-sub-category' element={<AddSubCategory/>} />
           </Route>


              
        </Routes>
      </Router>      
      </div>
    </body>
    
    </>
  );
}

export default App;
