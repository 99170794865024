import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'

export default function CourseManagement() {
  return (
    <>
    <Sidebar/>
    <div id="content-wrapper" className="d-flex flex-column">
       <div id="content">
      <Header/>

  
  <div className="container-fluid">
    <div className="row">

        <h1 className="h3 mb-4 text-gray-800">Course Management</h1>

        <div className="col-xl-3 col-md-6 mb-4">
            <div className="card bg-primary text-white shadow h-100 py-2">
                <div className="card-body">
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                            <div className="text-md font-weight-bold text-white text-uppercase mb-1">
                            Active Courses</div>
                            <div className="h3 mb-0 font-weight-bold text-white-800">15</div>
                        </div>
                        <div className="col-auto">
                            <i className="fa-solid fa-book fa-2x text-white-50"></i>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
                <div className="card bg-success shadow h-100 py-2">
                    <div className="card-body">
                        <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                                <div className="text-md font-weight-bold text-white text-uppercase mb-1">
                                Pending Courses</div>
                                <div className="h3 mb-0 font-weight-bold text-white">10</div>
                            </div>
                            <div className="col-auto">
                                <i className="fa-solid fa-book fa-2x text-white-50"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card bg-info shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-md font-weight-bold text-white text-uppercase mb-1">Paid Courses
                                            </div>
                                            <div class="row no-gutters align-items-center">
                                                <div class="col-auto">
                                                    <div class="h3 mb-0 mr-3 font-weight-bold text-white">20</div>
                                                </div>
                                                <div class="col">
                                                    <div class="progress progress-sm mr-2">
                                                        {/* <div class="progress-bar bg-info" role="progressbar"style="width: 50%"></div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fa-solid fa-book fa-2x text-white-50"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
           
             

                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card bg-warning shadow h-100 py-2">
                                <div class="card-body">
                                    <div class="row no-gutters align-items-center">
                                        <div class="col mr-2">
                                            <div class="text-md font-weight-bold text-white text-uppercase mb-1">
                                            Free Courses</div>
                                            <div class="h3 mb-0 font-weight-bold text-white">18</div>
                                        </div>
                                        <div class="col-auto">
                                            <i class="fa-solid fa-book fa-2x text-white-50"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>






    </div>
    <div className="row">
        <div className="col-md-12">

        <div class="card shadow mb-4">                           
        <a href="#collapseCardExample" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardExample">
                <h6 class="m-0 font-weight-bold text-primary">Course Listing</h6>
        </a>
            <div class="collapse show" id="collapseCardExample">
                <div class="card-body">
                   <div className="row">
                    <div className="col-md-3">
                        <div class="mb-3">
                            <label class="mb-2" for="inputEmailAddress">Category</label>
                            <select className='form-select'>
                                <option value="">Category</option>
                                <option value="">Category</option>
                                <option value="">Category</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div class="mb-3">
                            <label class="mb-2" for="inputEmailAddress"> Status</label>
                            <select className='form-select'>
                                <option value=""> Status</option>
                                <option value="">Completed</option>
                                <option value="">Incomplete</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div class="mb-3">
                            <label class="mb-2" for="inputEmailAddress">Instructor</label>
                            <select className='form-select'>
                                <option value="">Instructor</option>
                                <option value="">Instructor</option>
                                <option value="">Instructor</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <div class="mb-3">
                            <label class="mb-2" for="inputEmailAddress">Price</label>
                            <select className='form-select'>
                                <option value="">2000</option>
                                <option value="">4000</option>
                                <option value="">6000</option>
                            </select>
                        </div>
                    </div>





                    </div> 
                </div>
            </div>
        </div>



        </div>

        <div className="col-md-12">

        <div class="card shadow mb-4">
                                
                                <div  class="d-flex card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseCardExample">
                                <form class="d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 w-50 navbar-search">
                                    <div class="input-group border"><input type="text" class="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2"/>
                                    <div class="input-group-append"><button class="btn btn-primary" type="button"><i class="fas fa-search fa-sm"></i></button>
                                    </div>
                                    </div>
                               </form>
                                   <Link to="add-course" className='btn btn-primary'>Add Course</Link>
                                </div>
                                
                                <div class="collapse show" id="collapseCardExample">
                                    <div class="card-body">
                                    <div class="table-responsive">
                                    <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Course Banner</th>
                                            <th>Course Name</th>
                                            <th>Category</th>
                                            <th>Subcategory</th>
                                            <th>Course type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                {
                    [...new Array(10)].map(data=>{
                        return(
<tr>
                                            <td>Course Banner</td>
                                            <td>Course Name</td>
                                            <td>Category</td>
                                            <td>Subcategory</td>
                                            <td>Course type</td>
                                        </tr>
                        )
                    })
                }
                                    


                                    </tbody>
                                    </table>
                                    </div>
                                    </div>
                                </div>
                            </div>






        </div>
    </div>

  </div>
  </div>
    <Footer/>
    
  </div> 
    
    
    </>
  )
}
