import React from 'react'

export default function Footer() {
  return (
    <>
    
    {/* <!-- Footer --> */}
            <footer class="sticky-footer bg-white">
                <div class="container my-auto">
                    <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Learn2Connect 2022</span>
                    </div>
                </div>
            </footer>
            {/* <!-- End of Footer --> */}
    
    
    </>
  )
}
