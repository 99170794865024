import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'

export default function AddCategory() {
  return (
    <>
    <Sidebar/>
    <div id="content-wrapper" class="d-flex flex-column">
       <div id="content">
      <Header/>
  <div class="container-fluid">
  <h1 class="h3 mb-4 text-gray-800">Add Category</h1>

  <div className="row">
    <div className="col-md-12">
        <div class="card shadow mb-4">
                                    
            <a href="#collapseCardExample" class="d-block card-header py-3" data-toggle="collapse"
                role="button" aria-expanded="true" aria-controls="collapseCardExample">
                <h5 class="m-0 font-weight-bold text-primary">Add Category</h5>
            </a>
                                
            <div class="collapse show" id="collapseCardExample">
                <div class="card-body">
                <div className="row">
                    
                    <div class="mb-3 col-md-6">
                        <label class="mb-1" for="inputLastName"> Category Name</label>
                        <input class="form-control" id="inputLastName" type="text" placeholder="" value=""/>
                    </div>

                    <div class="mb-3 col-md-6">
                                <label class="mb-1" for="inputFirstName">About the Category </label>
                                <input class="form-control" id="inputFirstName" type="text" placeholder="About the Category" value=""/>
                    </div>

                </div> {/* row */}
                <div className="row">
                    <div class="mb-3 col-md-12">
                        <label class="mb-1" for="inputLastName"> Description </label>
                        <textarea class="form-control" id="" type="text" placeholder="" value=""></textarea>
                    </div>
                </div> {/* row */}

                <div className="row">
                    <div class="mb-3 col-md-6">
                        <label class="mb-1" for="inputLastName"> Image </label>
                        <input class="form-control" id="inputFirstName" type="file" placeholder="Last Name" value=""/>
                    </div>
                </div> {/* row */}



                <div className="row">
                    <div className="col-md-4">
                        <button className='btn btn-primary w-50'>Save</button>
                    </div>
                </div>
                </div>
            </div>
        </div>

    </div>



    

    </div>

  </div>
  </div>
    <Footer/>
    
  </div> 
    
    
    </>
  )
}
