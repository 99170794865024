import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'

export default function Dashboard() {
  return (
    <>
    <Sidebar/>
    <div id="content-wrapper" class="d-flex flex-column">
       <div id="content">
      <Header/>

  
  <div class="container-fluid">
  <h1 class="h3 mb-4 text-gray-800">Dashboard</h1>

  </div>
  </div>
    <Footer/>
    
  </div> 
    
    
    </>
  )
}
