import React from 'react'
import Footer from '../Footer/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { Link } from 'react-router-dom'

export default function CategoryManagement() {
  return (
    <>
    <Sidebar/>
    <div id="content-wrapper" class="d-flex flex-column">
       <div id="content">
      <Header/>

  
  <div class="container-fluid">
  <h1 class="h3 mb-4 text-gray-800">Category Management</h1>

<div className="row">
    <div className="col-md-12">
    <div class="card shadow mb-4">
        <div class="card-header py-3 d-flex justify-content-between align-items-center">
            <h6 class="m-0 font-weight-bold text-primary">Category Management</h6>
            <Link to="add-category" className='btn btn-primary'>Add Category</Link>
        </div>
        <div class="card-body">
     
        <div class="table-responsive">
        <table class="table table-bordered tutortable" id="dataTable" width="100%" cellspacing="0">
            <thead>
                <tr>
                    <th>Image</th>
                    <th>Category Name</th>
                    <th>About the category</th>
                    <th>Description</th>
                    <th className='text-center'>Action</th>
                </tr>
            </thead>
            <tbody>
            {
 [...new Array(10)].map(data=>{
    return(
        <tr>
                    <td>Image</td>
                    <td>Category Name</td>
                    <td>About the category</td>
                    <td>Description</td>
                    <td align='center'>

                    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="edit-category"><button className='btn btn-warning btn-sm mr-2'>Edit</button></Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div>


                    </td>
                </tr>
    )
 })

            }
            </tbody>
        </table>  
        </div>



        </div>
    </div>



    </div>
</div>

  </div>
  </div>
    <Footer/>
    
  </div> 
    
    
    </>
  )
}
