import React from 'react'
import '../../Pages/Pages.scss'
import TutorImg from '../../assest/img/undraw_profile_2.svg'
import { Button } from 'bootstrap'
import Footer from '../Footer/Footer'
import Header from '../Layout/Header'
import Sidebar from '../Layout/Sidebar'
import { Outlet, Link } from "react-router-dom";

export default function TutorManagement() {
    const number = [...new Array(20)]
  return (
    <>
    <Sidebar/>
    <div id="content-wrapper" class="d-flex flex-column">
       <div id="content">
      <Header/>
    <div class="container-fluid">
    <h1 class="h3 mb-4 text-gray-800">Tutor Management</h1>

    <div class="card shadow mb-4">
                        <div class="card-header py-3 d-flex justify-content-between align-items-center">
                            <h6 class="m-0 font-weight-bold text-primary">Tutor Management</h6>
                            <Link to="add-teacher" className='btn btn-primary'>Add a Teacher</Link>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered tutortable" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>No Of Active Coursers</th>
                                            <th>Start date</th>
                                            <th align='center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
        {
            number.map(data=>{

        return (
            <tr>
    <td>
        <div className='img-profile rounded-circle'>
            <img src={TutorImg} alt="Tutor image" />
        </div>
    </td>
    <td>System Architect</td>
    <td>admin@gmail.com</td>
    <td>61</td>
    <td>2011/04/25</td>
    <td align='center'>
    <div className="">
        <button className='btn btn-primary btn-sm mr-2'>View</button>
        <Link to="add-teacher"><button className='btn btn-warning btn-sm mr-2'>Edit</button></Link>
        <button className='btn btn-danger btn-sm'>Delete</button>
    </div>  
    </td>
</tr>
        )

            })
        }


                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


</div>
</div>
    <Footer/>
    
  </div>
    </>
  )
}
